@charset "utf-8";

@import '../mixin';
@import '../variable';

main {
    &.error {
        position: relative;
        .firstRow {
            background-color: #F7F7F7;
            padding: 71px 0 96px;
            @media screen and (min-width: $xxl) {
                padding: 100px 0;
            }
            @media screen and (max-width: $lg) {
                padding: 50px 0;
            }
            @media screen and (max-width: $sm) {
                padding: 40px 0;
            }

            .inner {
                text-align: center;
            }

            h2 {
                font-size: 48px;
                margin-bottom: 20px;
                font-weight: bold;
                color: $color-brand;
                @media screen and (max-width: 767px) {
                    font-size: 30px;
                }
            }

            p {
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                }
            }
            
        }
    }
}