@charset "utf-8";

/* CUSTOM VARIABLE */
$color-base: #482D1D;
$color-brand: #004821;
$color-secondary: #482D1D;
$color-third: #A29288;
$color-tertiary:#FFF9EB;
$color-gray: #818181;
$color-link: #004821;
$color-button: #004821;
$color-button-second: rgba(215, 182, 0, 0.46);
$color-hover: #A90017;
$color-white: #FFF;
$color-border: #F0F0EE;

/*TRANSITION*/
$animate: all 0.3s ease;

/*BREAKPOINT*/
$sm: 767px;
$md: 1000px;
$lg: 1200px;
$xl: 1366px;
$xxl: 1600px;