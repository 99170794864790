@charset "utf-8";

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transition($value) {
    -webkit-transition: $value;
       -moz-transition: $value;
        -ms-transition: $value;
         -o-transition: $value;
            transition: $value;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($value) {
    -webkit-transition-duration: $value;
       -moz-transition-duration: $value;
        -ms-transition-duration: $value;
         -o-transition-duration: $value;
            transition-duration: $value;
}
@mixin transition-delay($value) {
    -webkit-transition-delay: $value;
       -moz-transition-delay: $value;
        -ms-transition-delay: $value;
         -o-transition-delay: $value;
            transition-delay: $value;
}

@mixin transform($value) {
    -webkit-transform: $value;
       -moz-transform: $value;
        -ms-transform: $value;
         -o-transform: $value;
            transform: $value;
}
@mixin transform-style($value) {
    -webkit-transform-style: $value;
       -moz-transform-style: $value;
        -ms-transform-style: $value;
         -o-transform-style: $value;
            transform-style: $value;
}
@mixin transform-origin($value) {
    -webkit-transform-origin: $value;
       -moz-transform-origin: $value;
        -ms-transform-origin: $value;
         -o-transform-origin: $value;
            transform-origin: $value;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
      -ms-flex: $values;
          flex: $values;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
     -moz-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
     -moz-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}
 
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}
 
@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}
 
@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
       -moz-box-shadow: $value;
        -ms-box-shadow: $value;
         -o-box-shadow: $value;
            box-shadow: $value;
}

@mixin box-sizing($value) {
    -webkit-box-sizing: $value;
       -moz-box-sizing: $value;
        -ms-box-sizing: $value;
         -o-box-sizing: $value;
            box-sizing: $value;
}

@mixin filter($value) {
    -webkit-filter: $value;
       -moz-filter: $value;
        -ms-filter: $value;
         -o-filter: $value;
            filter: $value;
}


// EXTENDS
%background-cover {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
}